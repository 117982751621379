import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ContactForm from "../components/contact"
import SEO from "../components/seo"
import {Container,Row,Col} from 'react-bootstrap'


const mobileDesktop =()=>{
    return(
    <Layout>
        <SEO title="AeroSpace" />
        <div >
            <Image alt="military" filename="airospace-baner.png"  />
        </div>
        <Container>
            <Row className="servicesPage">
                <h2 className="servicePageHead">Aerospace Engineering Design Services</h2>
            </Row>
            <hr className="embhr" />
            <Row>
               
                

                <Col sm={12} lg={6} className="servicePagePara">
                Trace Width Technology Solutions has extensive experience in the design and development of devices and technology used in the aerospace industry. From circuit boards to software, we can help you achieve the highest quality for your engineering needs with our proven implementation methods and years of experience.
                </Col>
                <Col sm={12} lg={6}>
                    <Image alt="military" filename="aerospace-inside.png"  />
                </Col>
                
               
            </Row>
            </Container>
            
        <div className="headerForm"> 
            <br/>
            
            <ContactForm  />
        </div>
          
    </Layout>
);

}

export default mobileDesktop;

